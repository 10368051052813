import { z } from "zod";

export enum UiStyle {
  Default = "default",
  Bubble = "bubble",
  Brick = "brick",
  Gradient = "gradient",
  Sleek = "sleek",
  Subtle = "subtle",
  Elevate = "elevate",
}

export const stylingContextSchema = z.object({
  uiStyle: z
    .enum([
      UiStyle.Default,
      UiStyle.Bubble,
      UiStyle.Brick,
      UiStyle.Gradient,
      UiStyle.Sleek,
      UiStyle.Subtle,
      UiStyle.Elevate,
    ])
    .optional(),
});

export type StylingContext = z.infer<typeof stylingContextSchema>;
