import { z } from "zod";

export type ElementState = {
  name: string;
  texts?: readonly (readonly [string, string])[];
  slots?: Record<string, ElementState[]>;
  lists?: Record<string, ElementState[]>;
};

export const elementStateSchema: z.ZodType<ElementState> = z.object({
  name: z.string(),
  texts: z.optional(z.array(z.tuple([z.string(), z.string()]))),
  slots: z.optional(z.record(z.array(z.lazy(() => elementStateSchema)))),
  lists: z.optional(z.record(z.array(z.lazy(() => elementStateSchema)))),
});
