import * as React from "react";
import { DocumentNode } from "../ast";
import { ComponentOptions } from "../tagging";
import { ElementState, elementStateSchema } from "../editing";
import { useSectionState, useSectionStateUpdate } from "./section-state";
import { ElementTree } from "./tree-view";
import { ErrorBoundary } from "../shared";
import { FormattedCode } from "./export";
import "./styles.css";
import { stylingContextSchema } from "../styling";
import { z } from "zod";

function StateOverride() {
  const sectionState = useSectionState();
  const updateSectionState = useSectionStateUpdate();
  const setElement = React.useCallback((element: ElementState | undefined) => {
    updateSectionState?.({ element, source: "override" });
  }, []);

  const [expanded, setExpanded] = React.useState(false);
  const [value, setValue] = React.useState(() => {
    if (sectionState?.source === "override") {
      return JSON.stringify(sectionState.element, null, 2);
    }
    return "";
  });
  React.useEffect(() => {
    if (sectionState?.source === "override") {
      setValue(JSON.stringify(sectionState?.element, null, 2));
    }
  }, [sectionState]);

  const [error, setError] = React.useState<string>();
  const removeOverrides = () => {
    setError(undefined);
    setValue("");
    setElement(undefined);
    setExpanded(false);
  };
  const apply = () => {
    setError(undefined);
    if (value === "") {
      setElement(undefined);
      setExpanded(false);
      return;
    }
    try {
      setElement(elementStateSchema.parse(JSON.parse(value)));
      setExpanded(false);
    } catch (error: unknown) {
      console.error(error);
      setError("Failed to parse state override, please check console for details.");
    }
  };
  if (expanded) {
    return (
      <div className="state-override">
        <p>Paste Relume Component Element State here:</p>
        <textarea
          value={value}
          className="tagging-helper-textarea"
          onInput={(event) => {
            setValue(event.currentTarget.value);
          }}
        />
        <div>
          {error && <p style={{ color: "rgb(161, 6, 3)" }}>{error}</p>}
          <div className="tagging-helper-button-group">
            <button className="tagging-helper-button" onClick={apply}>
              Apply
            </button>
            <button className="tagging-helper-button" onClick={() => setExpanded(false)}>
              Cancel
            </button>
            <button className="tagging-helper-button" onClick={removeOverrides}>
              Remove overrides
            </button>
          </div>
        </div>
      </div>
    );
  }

  if (sectionState.source == null) {
    return (
      <button
        style={{
          border: "2px solid rgb(242, 125, 29)",
          backgroundColor: "rgb(242, 125, 29, 0.25)",
        }}
        className="tagging-helper-banner"
        onClick={() => setExpanded(true)}
      >
        No element state provided, import and link one in this story or click here to set an
        override
      </button>
    );
  }
  if (sectionState.source === "override") {
    return (
      <button
        style={{
          border: "2px solid rgb(12, 235, 0)",
          backgroundColor: "rgb(12, 235, 0, 0.25)",
        }}
        className="tagging-helper-banner"
        onClick={() => setExpanded(true)}
      >
        Override in effect, click here to change it
      </button>
    );
  }
  return (
    <button
      style={{
        border: "2px solid rgb(235, 211, 0)",
        backgroundColor: "rgb(235, 211, 0, 0.25)",
      }}
      className="tagging-helper-banner"
      onClick={() => setExpanded(true)}
    >
      Default element state in effect, click here to set an override
    </button>
  );
}

function StyleProperty({ name, schema }: { name: string; schema: z.ZodType }) {
  const { stylingContext } = useSectionState();
  const updateSectionState = useSectionStateUpdate();
  const [value, setValue] = React.useState(
    stylingContext != null ? JSON.stringify(Reflect.get(stylingContext, name)) : "",
  );
  const [effective, setEffective] = React.useState(true);
  const update = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.currentTarget.value);
    if (event.currentTarget.value === "") {
      updateSectionState?.({
        stylingContext: { ...stylingContext, [name]: undefined },
      });
      setEffective(true);
      return;
    }
    try {
      const parsed = schema.parse(JSON.parse(event.currentTarget.value));
      updateSectionState?.({
        stylingContext: { ...stylingContext, [name]: parsed },
      });
      setEffective(true);
    } catch (error: unknown) {
      setEffective(false);
    }
  };

  return (
    <div className="tagging-helper-style-row">
      <label>{name}</label>
      <input className="tagging-helper-style-input" type="text" value={value} onChange={update} />
      <span>{effective ? "✅" : "❌"}</span>
    </div>
  );
}

function StyleOverride() {
  return (
    <div>
      {Object.entries(stylingContextSchema.shape).map(([key, value]) => (
        <StyleProperty key={key} name={key} schema={value} />
      ))}
    </div>
  );
}

export type TaggingHelperProps = {
  template?: DocumentNode;
  options?: ComponentOptions;
};

export function TaggingHelper({ template, options }: TaggingHelperProps) {
  const [isOpen, toggleIsOpen] = React.useReducer(
    (isOpen) => !isOpen,
    Boolean(window.localStorage.getItem("relume-tagging-helper-open")) ?? false,
  );

  React.useEffect(() => {
    const callback = (event: KeyboardEvent) => {
      if (event.key === "h") {
        toggleIsOpen();
      }
    };
    window.addEventListener("keydown", callback);
    if (isOpen) {
      window.localStorage.setItem("relume-tagging-helper-open", `true`);
    } else {
      window.localStorage.removeItem("relume-tagging-helper-open");
    }
    return () => {
      window.removeEventListener("keydown", callback);
    };
  }, [isOpen]);

  const sectionState = useSectionState();

  return (
    <div>
      <button className="tagging-helper-toggle" onClick={toggleIsOpen}>
        Slot Helper
      </button>
      {isOpen && (
        <div className="tagging-helper-panel">
          <h3 className="tagging-helper-heading">Data source</h3>
          <StateOverride />
          <h3 className="tagging-helper-heading">Styles</h3>
          <StyleOverride />
          {sectionState.element && (
            <>
              <ErrorBoundary
                fallback={
                  <div>Failed to display element tree, please check console for details</div>
                }
              >
                <h3 className="tagging-helper-heading">Element State Visualization</h3>
                <div className="tagging-helper-tree">
                  <ElementTree
                    element={sectionState.element}
                    template={template}
                    ignoredTexts={options?.ignoredTexts}
                  />
                </div>
              </ErrorBoundary>
              <h3 className="tagging-helper-heading">Generated Source code</h3>
              <div className="tagging-helper-code">
                <pre>
                  <ErrorBoundary
                    fallback={
                      <div>
                        Failed to generate component source code, please check console for details.
                      </div>
                    }
                  >
                    <FormattedCode ast={template} />
                  </ErrorBoundary>
                </pre>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
}
