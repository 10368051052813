import { ArrayValueNode, ObjectValueNode, Node, BaseDocumentNode } from "./types";

/**
 * search for nodes that satisfy @param condition from the given @param node
 * children of the matched node won't be searched
 *
 * @param includeSelf whether to include the node itself in the search
 */
export function* searchFromTree<N extends Node>(
  node: Node,
  condition: (node: Node) => node is N,
  includeSelf = false,
): Generator<N> {
  if (includeSelf && condition(node)) {
    yield node;
  } else if (node instanceof ObjectValueNode) {
    for (const [, item] of node.entries) {
      yield* searchFromTree<N>(item, condition, true);
    }
  } else if (node instanceof ArrayValueNode) {
    for (const item of node.items) {
      yield* searchFromTree<N>(item, condition, true);
    }
  } else {
    if (node instanceof BaseDocumentNode) {
      yield* searchFromTree<N>(node.props, condition, true);
    }
  }
}
