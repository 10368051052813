import { Import, ImportForm, isTrackedObject, getTracker, trackImport } from "../tagging";

class ImportRegistry {
  private imports = new Map<unknown, unknown>();

  registerImport<T>(value: T, name: string, form: ImportForm, path: string): T {
    const registration = trackImport(name, value, form, path);
    if (this.imports.has(value)) {
      console.log(`Import already registered`, value);
      throw new Error(`Import already registered`);
    }
    this.imports.set(value, registration);
    return registration as T;
  }
  registerImports(list: [unknown, string, ImportForm, string][]) {
    for (const [value, name, form, path] of list) {
      if (this.imports.has(value)) {
        console.log(`Import already registered`, value);
        throw new Error(`Import already registered`);
      }
      this.imports.set(value, trackImport(name, value, form, path));
    }
  }
  registerDerived(list: [unknown, unknown][]) {
    for (const [value, tracked] of list) {
      // todo: hide these defensive checks behind env flag
      if (!isTrackedObject(tracked)) {
        throw new Error(`Expected a tracked object`);
      }
      const tracker = getTracker(tracked);
      if (!(tracker.target instanceof Import)) {
        throw new Error(`Expected the source to be an import`);
      }
      if (!this.isRegistered(tracker.target.value)) {
        throw new Error(`Expected the source to be already registered`);
      }
      this.imports.set(value, tracked);
    }
  }

  isRegistered(value: unknown): boolean {
    return this.imports.has(value);
  }
  resolve(value: unknown): unknown {
    return this.imports.get(value);
  }
}

export const importRegistry = new ImportRegistry();
