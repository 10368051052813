import * as React from "react";
import { DocumentNode } from "../ast";
import * as prettier from "prettier";
import * as babelPlugin from "prettier/plugins/babel";
import * as estreePlugin from "prettier/plugins/estree";
import { renderToSource } from "../rendering";
import { applyEdits } from "../editing";
import { useSectionState } from "./section-state";
import { applyStyling } from "../styling";

export function FormattedCode({ ast }: { ast?: DocumentNode }) {
  const [formattedCode, setCode] = React.useState("...");
  const { element, stylingContext = {} } = useSectionState();

  React.useEffect(() => {
    if (ast == null) {
      setCode("...");
      return;
    }
    prettier
      .format(
        renderToSource(
          element != null ? applyStyling(applyEdits(ast, element), stylingContext) : ast,
        ),
        {
          parser: "babel",
          plugins: [estreePlugin, babelPlugin],
        },
      )
      .then(setCode)
      .catch((error) => {
        console.error(error);
        setCode("code formatting failed, please check console for details");
      });
  }, [ast]);
  return formattedCode;
}
