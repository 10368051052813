import {
  ComponentNode,
  DocumentNode,
  HTMLNode,
  Node,
  PrimitiveValueNode,
  ObjectValueNode,
  FunctionValueNode,
  ArrayValueNode,
  BaseValueNode,
} from "../ast";
import { UnreachableError } from "../shared";
import { ElementAnchor, SlotAnchor, ListAnchor } from "../tagging";
import { ElementState } from "./types";

function match(node: ComponentNode, targetName: string): boolean {
  const nameProps = node.props.fields.get("name");
  const name =
    nameProps instanceof PrimitiveValueNode && typeof nameProps.value === "string"
      ? nameProps.value
      : undefined;
  if (name != null) {
    return name === targetName;
  }

  const matchProp = node.props.fields.get("match");
  const match = matchProp instanceof FunctionValueNode ? matchProp.call : undefined;
  if (match != null) {
    return match(targetName);
  }

  throw new Error("Invalid anchor definition, expected name or match function.");
}

type EditingOptions = {
  strict: boolean;
};

export function applyEdits(
  node: ObjectValueNode,
  element: ElementState,
  options?: EditingOptions,
): ObjectValueNode;
export function applyEdits(
  node: DocumentNode,
  element: ElementState,
  options?: EditingOptions,
): DocumentNode;
export function applyEdits(node: Node, element: ElementState, options?: EditingOptions): Node;
export function applyEdits(
  node: Node,
  element: ElementState,
  options: EditingOptions = { strict: false },
): Node {
  const textOverrides = new Map(element.texts ?? []);
  if (node instanceof PrimitiveValueNode && typeof node.value === "string") {
    const override = textOverrides.get(node.value);
    return new PrimitiveValueNode(override ?? node.value);
  } else if (node instanceof ArrayValueNode) {
    return new ArrayValueNode(node.items.map((item) => applyEdits(item, element, options)));
  } else if (node instanceof ObjectValueNode) {
    return new ObjectValueNode(node.entries.map(([k, v]) => [k, applyEdits(v, element, options)]));
  } else if (node instanceof BaseValueNode) {
    return node;
  }

  if (
    node instanceof ComponentNode &&
    (node.Component === SlotAnchor || node.Component === ListAnchor)
  ) {
    let slot: ElementState[] | undefined;
    if (node.Component === ListAnchor) {
      [, slot] =
        (element.lists && Object.entries(element.lists).find(([key]) => match(node, key))) ?? [];
    } else {
      [, slot] =
        (element.slots && Object.entries(element.slots).find(([key]) => match(node, key))) ?? [];
    }
    if (slot == null) {
      if (options.strict) {
        throw new Error("No slot matched");
      } else {
        return new ArrayValueNode(node.children);
      }
    }

    const candidates = node.children.filter(
      (el): el is ComponentNode => el instanceof ComponentNode && el.Component === ElementAnchor,
    );

    return new ArrayValueNode(
      slot
        .map((item) => {
          const candidate = candidates.find((el) => match(el, item.name));
          if (candidate != null) {
            return applyEdits(new ArrayValueNode(candidate.children), item, options);
          } else if (options.strict) {
            throw new Error(`Element with name "${item.name}" is not defined`);
          }
        })
        .filter((el) => el != null),
    );
  }
  if (node instanceof HTMLNode || node instanceof ComponentNode) {
    return node.cloneWithProps(applyEdits(node.props, element, options));
  }
  throw new UnreachableError(node);
}
