import {
  ComponentNode,
  HTMLNode,
  Node,
  ObjectValueNode,
  ArrayValueNode,
  BaseValueNode,
  DocumentNode,
} from "../ast";
import { UnreachableError } from "../shared";
import { StylingContext } from "./types";

export function applyStyling(node: ObjectValueNode, styles: StylingContext): ObjectValueNode;
export function applyStyling(node: DocumentNode, styles: StylingContext): DocumentNode;
export function applyStyling(node: Node, styles: StylingContext): Node;
export function applyStyling(node: Node, styles: StylingContext): Node {
  if (node instanceof ArrayValueNode) {
    return new ArrayValueNode(node.items.map((item) => applyStyling(item, styles)));
  } else if (node instanceof ObjectValueNode) {
    return new ObjectValueNode(node.entries.map(([k, v]) => [k, applyStyling(v, styles)]));
  } else if (node instanceof BaseValueNode) {
    return node;
  } else if (node instanceof HTMLNode || node instanceof ComponentNode) {
    return node.cloneWithProps(applyStyling(node.props, styles));
  }
  throw new UnreachableError(node);
}
