import { createContextManager } from "../shared";
import { StylingContext } from "./types";

const withStylingContext = createContextManager<StylingContext | undefined>(undefined);

export function provideStylingContext<T>(callback: () => T, context: StylingContext): T {
  return withStylingContext((_, setContext) => {
    setContext(context);
    return callback();
  });
}

export function inject<K extends keyof StylingContext>(value: K): StylingContext[K] {
  return withStylingContext((context) => {
    if (context == null) {
      throw new Error("Styling context not provided.");
    }
    return context[value];
  });
}
