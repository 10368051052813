import * as React from "react";
import { isTrackedObject, getTracker, ReferenceTracker } from "../tagging";
import {
  Node,
  NullishValueNode,
  PrimitiveValueNode,
  FunctionValueNode,
  ArrayValueNode,
  ObjectValueNode,
  DocumentNode,
  HTMLNode,
  ComponentNode,
} from "../ast";
import { createContextManager, defined, UnreachableError } from "../shared";

const withRenderingContext = createContextManager<
  | {
      locales: Map<unknown, unknown>;
    }
  | undefined
>(undefined);

function dereference(
  tracker: ReferenceTracker,
  locales: Map<unknown, unknown>,
  last: unknown = null,
): unknown {
  if (locales.has(tracker)) {
    return locales.get(tracker)!;
  }
  let current = tracker.target.value;

  if (isTrackedObject(current)) {
    const inner = getTracker(current);
    current = dereference(inner, locales, current);
  }

  for (const access of tracker.accesses) {
    if (access.type === "get") {
      current = (current as never)[access.field];
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-types
      current = (current as Function).apply(last, access.args.map(render));
    }
  }
  locales.set(tracker, current);
  return current;
}

function followTrackedObject(value: object): unknown {
  return withRenderingContext((context) => {
    const locales = defined(context).locales;
    return dereference(getTracker(value), locales);
  });
}

function render(node: NullishValueNode): undefined;
function render(node: PrimitiveValueNode): PrimitiveValueNode["value"];
function render(node: FunctionValueNode): FunctionValueNode["call"];
function render(node: ArrayValueNode): unknown[];
function render(node: ObjectValueNode): Record<string, unknown>;
function render(node: DocumentNode): React.ReactNode;
function render(node: Node): unknown {
  if (node instanceof FunctionValueNode && isTrackedObject(node.call)) {
    return followTrackedObject(node.call);
  } else if (node instanceof NullishValueNode) {
    return undefined;
  } else if (node instanceof PrimitiveValueNode) {
    return node.value;
  } else if (node instanceof FunctionValueNode) {
    return node.call;
  } else if (node instanceof ArrayValueNode) {
    return node.items.map((item, index) => {
      if (item instanceof HTMLNode || item instanceof ComponentNode) {
        return render(
          item.cloneWithProps(
            item.props.transform((entries) => [...entries, ["key", new PrimitiveValueNode(index)]]),
          ),
        );
      }
      return render(item);
    });
  } else if (node instanceof ObjectValueNode) {
    return Object.fromEntries(node.entries.map(([k, v]) => [k, render(v)]));
  }
  if (node instanceof HTMLNode) {
    return React.createElement(node.tagName, render(node.props));
  }
  if (node instanceof ComponentNode) {
    const Component = isTrackedObject(node.Component)
      ? (followTrackedObject(node.Component) as React.ComponentType)
      : node.Component;
    return React.createElement(Component, render(node.props));
  }
  throw new UnreachableError(node);
}

export function renderToReactNode(node: DocumentNode): React.ReactNode {
  return withRenderingContext((_, setContext) => {
    setContext({ locales: new Map() });
    return <React.StrictMode>{render(node)}</React.StrictMode>;
  });
}
