import * as React from "react";
import { ElementState, elementStateSchema } from "../editing";
import { z } from "zod";
import { StylingContext } from "../styling";

export type SectionState = {
  element?: ElementState;
  source?: "override" | "default";
  stylingContext?: StylingContext;
};

const localStorageKey = "relume-tagging-helper-paste";

const sectionStateContext = React.createContext<SectionState>({});
const sectionStateUpdateContext = React.createContext<(state: SectionState) => void>(() => {});

export function useSectionState() {
  return React.useContext(sectionStateContext);
}

export function useSectionStateUpdate() {
  return React.useContext(sectionStateUpdateContext);
}

const saveSchema = z.object({
  id: z.string(),
  element: elementStateSchema,
});

function getSavedElement(id?: string) {
  if (id == null) {
    return;
  }
  try {
    const stored = window.localStorage.getItem(localStorageKey);
    if (stored != null) {
      const parsed = saveSchema.parse(JSON.parse(stored));
      if (parsed.id === id) {
        return parsed.element;
      }
    }
  } catch (error: unknown) {
    window.localStorage.removeItem(localStorageKey);
  }
}

export function SectionStateProvider({ children, id }: { children: React.ReactNode; id?: string }) {
  const [defaultElement, setDefaultElement] = React.useState<ElementState>();
  const [overrideElement, setOverrideElement] = React.useState<ElementState | undefined>(() =>
    getSavedElement(id),
  );
  const [stylingContext, setStylingContext] = React.useState<StylingContext>();
  React.useEffect(() => {
    setOverrideElement(getSavedElement(id));
  }, [id]);
  const state = React.useMemo(() => {
    let element: ElementState | undefined;
    let source: "override" | "default" | undefined;
    if (overrideElement != null) {
      element = overrideElement;
      source = "override";
    } else if (defaultElement != null) {
      element = defaultElement;
      source = "default";
    }
    return {
      element,
      source,
      stylingContext,
    };
  }, [overrideElement ?? defaultElement, stylingContext]);
  const setState = React.useCallback(
    (state: {
      element?: ElementState;
      source?: "override" | "default";
      stylingContext?: StylingContext;
    }) => {
      if (state.source === "default") {
        setDefaultElement(state.element);
      } else if (state.source === "override") {
        setOverrideElement(state.element);
        if (id != null && state.element != null) {
          window.localStorage.setItem(
            localStorageKey,
            JSON.stringify({ id, element: state.element }),
          );
        } else {
          window.localStorage.removeItem(localStorageKey);
        }
      }
      setStylingContext(state.stylingContext);
    },
    [],
  );
  return (
    <sectionStateContext.Provider value={state}>
      <sectionStateUpdateContext.Provider value={setState}>
        {children}
      </sectionStateUpdateContext.Provider>
    </sectionStateContext.Provider>
  );
}
