import { ComponentNode, FunctionValueNode, PrimitiveValueNode } from "../ast";

export function match(node: ComponentNode, targetName: string): boolean {
  const nameProps = node.props.fields.get("name");
  const name =
    nameProps instanceof PrimitiveValueNode && typeof nameProps.value === "string"
      ? nameProps.value
      : undefined;
  if (name != null) {
    return name === targetName;
  }

  const matchProp = node.props.fields.get("match");
  const match = matchProp instanceof FunctionValueNode ? matchProp.call : undefined;
  if (match != null) {
    return match(targetName);
  }

  throw new Error("Invalid anchor definition, expected name or match function.");
}
