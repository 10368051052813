import * as React from "react";
import { parseNode, DocumentNode } from "../ast";
import { applyEdits } from "../editing";
import { renderToReactNode } from "../rendering";
import {
  normalize,
  TaggingContextProvider,
  TaggingContextType,
  withRegistrationTracking,
} from "../tagging";
import { SectionStateProvider, useSectionState } from "./section-state";
import { TaggingHelper, TaggingHelperProps } from "./tagging";
import { ErrorBoundary } from "../shared";
import { applyStyling, provideStylingContext } from "../styling";

function ComponentRenderer({
  Component,
  setTemplate,
}: {
  Component: React.FunctionComponent;
  setTemplate: (ast?: DocumentNode) => void;
}) {
  const { element, stylingContext } = useSectionState();
  // We call the functional component via useMemo to surface hook usage within the template component.
  // Make sure you have registered the hooks you are using in the template component.
  const ast = React.useMemo(
    () =>
      normalize(
        parseNode(
          provideStylingContext(
            () => withRegistrationTracking(() => Component({})),
            stylingContext ?? {},
          ),
        ),
      ),
    [Component, stylingContext],
  );

  React.useEffect(() => {
    setTemplate(ast);
    return () => {
      setTemplate(undefined);
    };
  }, [ast]);
  try {
    return renderToReactNode(
      element != null
        ? applyStyling(applyEdits(ast, element, { strict: false }), stylingContext ?? {})
        : ast,
    );
  } catch (error) {
    console.error(error);
    return <div>Failed to render component, please check console for details.</div>;
  }
}

export function SectionDevToolsProvider({
  mode,
  webflowPreviewUrl,
  children,
  id,
  Portal,
}: {
  mode: "full" | "preview";
  webflowPreviewUrl: string;
  children: React.ReactNode;
  id?: string;
  Portal: React.ComponentType<{ children: React.ReactNode }>;
}) {
  const [helperProps, setHelperProps] = React.useState<TaggingHelperProps>({});
  const [taggingContext] = React.useState<TaggingContextType>(() => {
    return {
      renderComponent: (Component, options) => {
        const setTemplate = React.useCallback(
          (ast?: DocumentNode) => {
            setHelperProps({ options, template: ast });
          },
          [options],
        );
        return (
          <ErrorBoundary
            fallback={
              <div>
                Failed to parse component, please ensure no React hooks are used in a Template
                Component, check console for details.
              </div>
            }
          >
            <ComponentRenderer setTemplate={setTemplate} Component={Component} />
          </ErrorBoundary>
        );
      },
    };
  });
  return (
    <SectionStateProvider id={id}>
      <TaggingContextProvider value={taggingContext}>{children}</TaggingContextProvider>
      {mode === "full" && (
        <Portal>
          <TaggingHelper {...helperProps} />
          <a className="webflow-link" target="_blank" rel="noopener" href={webflowPreviewUrl}>
            Webflow Version
          </a>
        </Portal>
      )}
    </SectionStateProvider>
  );
}
