import {
  ComponentNode,
  DocumentNode,
  HTMLNode,
  Node,
  PrimitiveValueNode,
  ObjectValueNode,
  NullishValueNode,
  FunctionValueNode,
  ArrayValueNode,
} from "../ast";
import { UnreachableError } from "../shared";
import { ElementAnchor, SlotAnchor, ListAnchor } from "./components";

export const ELEMENT_NAME_KEY = "elementName";
export const SLOT_NAME_KEY = "slotName";
export const LIST_NAME_KEY = "listName";

/**
 * Converts inline elements and slots tagging to their respective anchor elements
 */
export function normalize(node: DocumentNode): DocumentNode;
export function normalize(node: Node): Node;
export function normalize(node: Node): Node {
  if (node instanceof ComponentNode || node instanceof HTMLNode) {
    const elementName = node.props.fields.get(ELEMENT_NAME_KEY);
    if (elementName != null) {
      if (!(elementName instanceof PrimitiveValueNode) || typeof elementName.value !== "string") {
        throw new Error("Invalid inline element name");
      }

      // normalize element by wrapping it with ElementAnchor
      return new ComponentNode(
        ElementAnchor,
        new ObjectValueNode([
          ["name", elementName],
          [
            "children",
            normalize(
              node.cloneWithProps(
                new ObjectValueNode(node.props.entries.filter(([key]) => key !== ELEMENT_NAME_KEY)),
              ),
            ),
          ],
        ]),
      );
    }

    const slotName = node.props.fields.get(SLOT_NAME_KEY);
    const listName = node.props.fields.get(LIST_NAME_KEY);
    if (slotName != null) {
      if (!(slotName instanceof PrimitiveValueNode) || typeof slotName.value !== "string") {
        throw new Error("Invalid inline slot name");
      }

      // normalize slot by wrapping its children with SlotAnchor
      return node.cloneWithProps(
        new ObjectValueNode(
          node.props.entries
            .filter(([key]) => key !== SLOT_NAME_KEY)
            .map(([key, value]) => {
              if (key === "children") {
                return [
                  key,
                  new ComponentNode(
                    SlotAnchor,
                    new ObjectValueNode([
                      ["name", slotName],
                      ["children", normalize(value)],
                    ]),
                  ),
                ];
              }
              return [key, normalize(value)];
            }),
        ),
      );
    } else if (listName != null) {
      if (!(listName instanceof PrimitiveValueNode) || typeof listName.value !== "string") {
        throw new Error("Invalid inline list name");
      }

      // normalize list by wrapping its children with ListAnchor
      return node.cloneWithProps(
        new ObjectValueNode(
          node.props.entries
            .filter(([key]) => key !== LIST_NAME_KEY)
            .map(([key, value]) => {
              if (key === "children") {
                return [
                  key,
                  new ComponentNode(
                    ListAnchor,
                    new ObjectValueNode([
                      ["name", listName],
                      ["children", normalize(value)],
                    ]),
                  ),
                ];
              }
              return [key, normalize(value)];
            }),
        ),
      );
    }
    return node.cloneWithProps(
      new ObjectValueNode(node.props.entries.map(([key, value]) => [key, normalize(value)])),
    );
  } else {
    if (
      node instanceof NullishValueNode ||
      node instanceof PrimitiveValueNode ||
      node instanceof FunctionValueNode
    ) {
      return node;
    } else if (node instanceof ArrayValueNode) {
      return new ArrayValueNode(node.items.map(normalize));
    } else if (node instanceof ObjectValueNode) {
      return new ObjectValueNode(node.entries.map(([key, value]) => [key, normalize(value)]));
    }
  }
  throw new UnreachableError(node);
}
