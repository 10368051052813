import * as React from "react";
import { createContextManager } from "../shared";
import {
  ArrayValueNode,
  ComponentNode,
  DocumentNode,
  FunctionValueNode,
  HTMLNode,
  Node,
  NullishValueNode,
  ObjectValueNode,
  PrimitiveValueNode,
  ValueNode,
} from "./types";

// context to track visited objects and detect circular references.
const withObjectPath = createContextManager<readonly unknown[]>([]);

/**
 * Turns a React node into an AST for editing and exporting.
 */
export function parseNode(node: React.ReactNode): DocumentNode;
export function parseNode(node: unknown): Node;
export function parseNode(node: unknown): Node {
  return withObjectPath((path, setPath) => {
    if (node == null) {
      return new NullishValueNode();
    }
    if (typeof node === "string" || typeof node === "number" || typeof node === "boolean") {
      return new PrimitiveValueNode(node);
    }
    if (typeof node === "function") {
      return new FunctionValueNode(node);
    }

    if (path.includes(node)) {
      console.error("Circular reference detected, visited objects: ", ...path, node);
      throw new Error("Circular reference detected, unable to serialize props and apply edits");
    }
    setPath([...path, node]);

    if (Array.isArray(node)) {
      return new ArrayValueNode(node.map(parseNode));
    }
    if (React.isValidElement(node)) {
      const elementType = node.type;
      if (node.props == null || typeof node.props !== "object") {
        console.error("Invalid props detected: ", node.props);
        throw new Error("Invalid props detected.");
      }

      // extract ref to undo the special props treatment by React
      const ref = "ref" in node ? node.ref : undefined;
      if (typeof elementType === "string") {
        return new HTMLNode(elementType, parseObject(node.props, { ref }));
      }
      return new ComponentNode(elementType, parseObject(node.props, { ref }));
    }
    if (typeof node === "object") {
      return parseObject(node);
    }

    console.error("Unsupported react node: ", node);
    throw new Error("Unsupported react node");
  });
}

function parseObject(target: object, extra?: { ref?: unknown }): ObjectValueNode {
  const entries: [string, ValueNode][] = Object.entries(target).map(([key, value]) => [
    key,
    parseNode(value),
  ]);
  if (extra?.ref != null) {
    entries.push(["ref", parseNode(extra.ref)]);
  }
  return new ObjectValueNode(entries);
}
