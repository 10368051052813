class BaseNode {}

export class BaseValueNode extends BaseNode {}

export class NullishValueNode extends BaseValueNode {}

export class PrimitiveValueNode extends BaseValueNode {
  constructor(public readonly value: string | number | boolean) {
    super();
  }
}

export class FunctionValueNode extends BaseValueNode {
  // eslint-disable-next-line @typescript-eslint/ban-types
  constructor(public readonly call: Function) {
    super();
  }
}

export class ArrayValueNode extends BaseValueNode {
  constructor(public readonly items: Node[]) {
    super();
  }
}

export class ObjectValueNode extends BaseValueNode {
  readonly fields: Map<string, Node>;
  constructor(public readonly entries: [string, Node][]) {
    super();
    this.fields = new Map(entries);
  }

  transform(callback: (entries: [string, ValueNode][]) => [string, ValueNode][]): ObjectValueNode {
    return new ObjectValueNode(callback(this.entries));
  }
}

export type ValueNode =
  | NullishValueNode
  | PrimitiveValueNode
  | FunctionValueNode
  | ArrayValueNode
  | ObjectValueNode;

export class BaseDocumentNode extends BaseNode {
  constructor(public readonly props: ObjectValueNode) {
    super();
  }

  get children(): Node[] {
    const children = this.props.fields.get("children");
    if (children instanceof ArrayValueNode) {
      return children.items;
    }
    if (
      children == null ||
      children instanceof NullishValueNode ||
      (children instanceof PrimitiveValueNode && typeof children.value === "boolean")
    ) {
      return [];
    }
    return [children];
  }
}

export class HTMLNode extends BaseDocumentNode {
  constructor(
    public readonly tagName: string,
    public readonly props: ObjectValueNode,
  ) {
    super(props);
  }

  cloneWithProps(props: ObjectValueNode): HTMLNode {
    return new HTMLNode(this.tagName, props);
  }
}

export class ComponentNode<P = object> extends BaseDocumentNode {
  constructor(
    public readonly Component: React.ComponentType<P>,
    public readonly props: ObjectValueNode,
  ) {
    super(props);
  }

  cloneWithProps(props: ObjectValueNode): ComponentNode<P> {
    return new ComponentNode(this.Component, props);
  }
}

export type DocumentNode = HTMLNode | ComponentNode;
export type Node = DocumentNode | ValueNode;
